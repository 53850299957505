import { s3Client } from './spacesClient';
import { PutObjectCommand } from "@aws-sdk/client-s3";

export const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

const uploadToSpaces = async (file) => {
  const params = {
    Bucket: 'vidstudiousr', // Replace with your actual bucket name
    Key: file.name,
    Body: file,
    ACL: 'public-read' // or 'private' depending on your needs
  };

  try {
    const data = await s3Client.send(new PutObjectCommand(params));
    console.log("Successfully uploaded file.", data);
    return `https://vidstudiousr.nyc3.digitaloceanspaces.com/${file.name}`;
  } catch (err) {
    console.error("Error uploading file: ", err);
    throw err;
  }
};

export { uploadToSpaces }; 