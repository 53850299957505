import { useState, useRef, useEffect } from 'react';
import { uploadToSpaces, validateFileSize, MAX_FILE_SIZE } from '../../utils/spacesUpload';
import { fetchWithTokenRefresh } from '../../utils/fetchWithTokenRefresh';
import { 
  LuAlignVerticalJustifyEnd,
  LuAlignVerticalJustifyStart,
  LuAlignVerticalSpaceAround 
} from "react-icons/lu";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";

const UGCPage = () => {
  const [text, setText] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [page, setPage] = useState(1);
  const [demos, setDemos] = useState([]);
  const [textPosition, setTextPosition] = useState('bottom');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [ugcVideos, setUgcVideos] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const intervalRef = useRef(null);
  const [isCreating, setIsCreating] = useState(false);
  
  const videos = [
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Ashley.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Blake.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Britt.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Chad.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Gigi.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Jay.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Jerry.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Joey.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Mellanie.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Miles.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Pierre.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Rachel.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Sydney.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Tina.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/blaire.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/debbie.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/sabrina.mp4'
    },
    {
      videoUrl: 'https://delivery626.nyc3.cdn.digitaloceanspaces.com/UGC/Ashton.mp4'
    },
  ];

  const itemsPerPage = 21;
  const totalPages = Math.ceil(videos.length / itemsPerPage);
  const paginatedVideos = videos.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const generateUniqueName = (originalName) => {
    const timestamp = Date.now();
    const extension = originalName.split('.').pop();
    return `demo_${timestamp}.${extension}`;
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 50 * 1024 * 1024) { 
      const url = URL.createObjectURL(file);
      setDemos([...demos, { videoUrl: url }]);
    } else {
      alert('File size must be less than 50MB');
    }
  };

  const fetchUgcVideos = async () => {
    try {
      const response = await fetchWithTokenRefresh('https://api.vidstudio.ai/user/ugc-videos', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        // Update videos while preserving state for existing videos
        setUgcVideos(prevVideos => {
          const videosMap = new Map();
          prevVideos.forEach(video => videosMap.set(video.jobId, video));
          data.videos.forEach(video => videosMap.set(video.jobId, video));
          return Array.from(videosMap.values())
            .sort((a, b) => new Date(b.startedAt) - new Date(a.startedAt));
        });
        
        // Check if we should continue polling
        const hasProcessing = data.videos.some(video => video.status === 'Processing');
        if (!hasProcessing && intervalRef.current) {
          clearInterval(intervalRef.current);
          setIsPolling(false);
        }
      }
    } catch (error) {
      console.error('Error fetching Influencer videos:', error);
    }
  };

  const handleCreateVideo = async () => {
    if (!selectedVideo || demos.length === 0) {
      alert('Please select an influencer video and upload at least one demo video');
      return;
    }

    try {
      setIsCreating(true);
      setIsPolling(true);

      // Get the demo file and prepare it
      const demoFile = demos[demos.length - 1];
      const response = await fetch(demoFile.videoUrl);
      const blob = await response.blob();
      
      if (blob.size > MAX_FILE_SIZE) {
        setIsPolling(false);
        throw new Error('File size exceeds 50MB limit');
      }

      // Create a temporary video entry that matches server structure
      const tempJobId = `temp_${Date.now()}`;
      const tempVideo = {
        jobId: tempJobId,
        status: 'Uploading',
        hookText: text,
        textPosition: textPosition,
        startedAt: new Date().toISOString(),
        demoVideoUrl: demoFile.videoUrl,
        selectedVideo: selectedVideo.videoUrl
      };

      // Add temporary video to the start of the list IMMEDIATELY
      setUgcVideos(prev => [tempVideo, ...prev]);
      
      // Upload the video
      const uploadedUrl = await uploadToSpaces(
        new File([blob], generateUniqueName('video.mp4'), { type: 'video/mp4' })
      );

      // Update to Processing status after upload
      setUgcVideos(prev => prev.map(video => 
        video.jobId === tempJobId 
          ? { ...video, status: 'Processing', demoVideoUrl: uploadedUrl }
          : video
      ));

      // Make the API call
      const apiResponse = await fetchWithTokenRefresh('https://api.vidstudio.ai/trigger-ugc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          hookText: text,
          textPosition: textPosition,
          selectedVideo: selectedVideo.videoUrl,
          demoVideoUrl: uploadedUrl
        }),
      });

      if (!apiResponse.ok) {
        const apiData = await apiResponse.json();
        throw new Error(apiData.message || 'Failed to start influencer job');
      }

      const apiData = await apiResponse.json();
      
      // Update the temporary video with the real jobId from the server
      setUgcVideos(prev => prev.map(video => 
        video.jobId === tempJobId 
          ? { ...video, jobId: apiData.jobId }
          : video
      ));

    } catch (error) {
      console.error('Error creating video:', error);
      setIsPolling(false);
      // Remove the temporary video on error
      setUgcVideos(prev => prev.filter(video => !video.jobId.startsWith('temp_')));
      alert(error.message || 'Failed to create video. Please try again.');
    } finally {
      setIsCreating(false);
    }
  };

  const getTextPositionStyle = () => {
    switch(textPosition) {
      case 'top':
        return { top: '15%' };
      case 'middle':
        return { top: '50%' };
      case 'bottom':
        return { top: '80%' };
      default:
        return { top: '80%' };
    }
  };

  useEffect(() => {
    const hasProcessingVideo = ugcVideos.some((video) => video.status === 'Processing');

    if (hasProcessingVideo && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        fetchUgcVideos();
      }, 10000);
    } else if (!hasProcessingVideo && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [ugcVideos]);

  useEffect(() => {
    fetchUgcVideos();
  }, []);

  useEffect(() => {
    // Auto-select first video if none is selected and videos are available
    if (!selectedVideo && videos.length > 0) {
      setSelectedVideo(videos[0]);
    }
  }, [videos, selectedVideo]);

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {!isMobile && <Sidebar />}
      <div className="flex-1 p-6 md:p-8 lg:p-12 relative overflow-y-auto max-w-full">
        {!isMobile ? (
          <div className="absolute top-4 right-7 flex items-center space-x-4">
            <Link
              to="/profile"
              className="text-sm text-black font-semibold hover:underline"
            >
              Account
            </Link>
          </div>
        ) : (
          <div className="flex justify-between mb-4">
            <button
              onClick={() => navigate("/dashboard/view")}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Videos
            </button>
            <button
              onClick={() => navigate("/profile")}
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-gray-900 transition-all duration-300"
            >
              Account
            </button>
          </div>
        )}

        <div className="p-8 max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold mb-8">Create UGC ads</h1>
          
          <div className="grid grid-cols-2 gap-8">
            <div>
              {/* Left Column */}
              <div className="space-y-8">
                {/* Hook */}
                <div>
                  <h2 className="text-xl font-semibold mb-4">1. Text</h2>
                  <div className="relative">
                    <input
                      type="text"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      placeholder="edit your text here"
                      className="w-full p-3 border rounded-full"
                    />
                  </div>
                </div>

                {/* UGC Video Grid */}
                <div>
                  <h2 className="text-xl font-semibold mb-4">2. Influencer Video</h2>
                  <div className="grid grid-cols-7 gap-2">
                    {paginatedVideos.map((video, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedVideo(video)}
                        className="aspect-square rounded-lg overflow-hidden cursor-pointer border-2 hover:border-blue-500"
                      >
                        <video
                          src={video.videoUrl}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4 items-center gap-4">
                    <button 
                      onClick={() => setPage(p => Math.max(1, p - 1))}
                      className="text-gray-600"
                    >
                      &lt;
                    </button>
                    <span>{page}/{totalPages}</span>
                    <button 
                      onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                      className="text-gray-600"
                    >
                      &gt;
                    </button>
                  </div>
                </div>

                {/* Demos */}
                <div>
                  <h2 className="text-xl font-semibold mb-4">3. Product Video <span className="font-normal text-sm text-gray-500">(max 50MB)</span></h2>
                  <div className="flex gap-2">
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="p-4 border-2 border-dashed rounded-lg hover:bg-gray-50"
                    >
                      +
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      accept="video/*"
                      className="hidden"
                    />
                    {demos.map((demo, index) => (
                      <div
                        key={index}
                        className="aspect-square w-16 rounded-lg overflow-hidden"
                      >
                        <video
                          src={demo.videoUrl}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Preview */}
            <div className="relative">
              {selectedVideo && (
                <>
                  <div className="aspect-[9/16] w-2/3 mx-auto bg-black rounded-lg overflow-hidden relative">
                    <video
                      src={selectedVideo.videoUrl}
                      autoPlay
                      loop
                      muted
                      className="w-full h-full object-cover"
                    />
                    <div 
                      className="absolute inset-x-0 flex items-center justify-center"
                      style={getTextPositionStyle()}
                    >
                      <p 
                        className="text-white text-xl text-center px-4 font-medium"
                        style={{
                          textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
                        }}
                      >
                        {text}
                      </p>
                    </div>
                    
                    <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-2 bg-black/50 rounded-lg p-1">
                      <button 
                        onClick={() => setTextPosition('top')}
                        className={`p-2 rounded ${textPosition === 'top' ? 'bg-white/20' : 'hover:bg-white/10'}`}
                      >
                        <LuAlignVerticalJustifyStart className="text-white" />
                      </button>
                      <button 
                        onClick={() => setTextPosition('middle')}
                        className={`p-2 rounded ${textPosition === 'middle' ? 'bg-white/20' : 'hover:bg-white/10'}`}
                      >
                        <LuAlignVerticalSpaceAround className="text-white" />
                      </button>
                      <button 
                        onClick={() => setTextPosition('bottom')}
                        className={`p-2 rounded ${textPosition === 'bottom' ? 'bg-white/20' : 'hover:bg-white/10'}`}
                      >
                        <LuAlignVerticalJustifyEnd className="text-white" />
                      </button>
                    </div>
                  </div>
                  <div className="w-2/3 mx-auto mt-4 text-center">
                    <button 
                      onClick={handleCreateVideo}
                      disabled={isCreating}
                      className="w-full bg-black text-white py-3 rounded-lg font-medium hover:bg-gray-800 transition-colors disabled:opacity-75 disabled:cursor-not-allowed"
                    >
                      {isCreating ? (
                        <div className="flex items-center justify-center space-x-2">
                          <div className="w-5 h-5 border-t-2 border-white border-solid rounded-full animate-spin"></div>
                          <span>Creating...</span>
                        </div>
                      ) : (
                        'Create Video'
                      )}
                    </button>
                    <p className="text-gray-500 text-sm mt-1">Cost: 50 Credits</p>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-12">
            <h2 className="text-2xl font-bold mb-6">Your Influencer Videos</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
              {ugcVideos.map((video) => (
                <div
                  key={video.jobId}
                  className="bg-white shadow-sm rounded-lg overflow-hidden transition duration-300 ease-in-out transform hover:shadow-lg hover:-translate-y-1"
                >
                  <div className="aspect-[9/16] bg-gray-100 w-full max-w-[200px] mx-auto">
                    {video.status === 'Completed' && video.video_url ? (
                      <video
                        src={video.video_url}
                        controls
                        className="w-full h-full"
                        key={video.video_url}
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center">
                        {video.status === 'Uploading' ? (
                          <div className="text-center">
                            <div className="w-10 h-10 border-t-4 border-blue-600 border-solid rounded-full animate-spin mx-auto mb-3"></div>
                            <p className="text-sm text-gray-700 font-medium">Uploading video (plz dont leave)</p>
                          </div>
                        ) : video.status === 'Processing' ? (
                          <div className="text-center">
                            <div className="w-10 h-10 border-t-4 border-blue-600 border-solid rounded-full animate-spin mx-auto mb-3"></div>
                            <p className="text-sm text-gray-700 font-medium">Creating video...</p>
                          </div>
                        ) : video.status === 'Failed' ? (
                          <p className="text-sm text-red-600 font-medium">
                            Sorry, this video had an error. Please try again.
                          </p>
                        ) : (
                          <p className="text-sm text-gray-700 font-medium">Video is processing...</p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="p-2">
                    <p className="text-xs text-gray-800 font-medium">
                      Hook: {video.hookText || 'No text available'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UGCPage;
