import { S3 } from "@aws-sdk/client-s3";

const s3Client = new S3({
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    endpoint: "https://nyc3.digitaloceanspaces.com", // Replace 'nyc3' with your region
    region: "us-east-1", // This is required for compatibility
    credentials: {
      accessKeyId: process.env.REACT_APP_SPACES_KEY,
      secretAccessKey: process.env.REACT_APP_SPACES_SECRET
    }
});

export { s3Client };